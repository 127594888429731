html {
    font-size: var(--font-size);
}

html,
body,
#root,
.App {
    height: 100%;
}

* {
    margin: 0;
}

body,
input,
button,
textarea {
    font-family: var(--font-family);
    font-weight: var(--font-weight);
    color: var(--color);
    background-color: var(--bakground);
}

.App[data-mode="dark"] {
    --color: var(--white);
    --bakground: var(--natural);
    color: var(--color);
    background-color: var(--bakground);
}

body {
    background: rgb(242, 249, 251);
    background: linear-gradient(90deg, rgba(242, 249, 251, 1) 0%, rgba(249, 242, 240, 1) 100%);
}

a {
    color: var(--primary);
    text-decoration: none;
    font-weight: var(--font-weight-medium);
    font-size: 1.1rem;
}

a:hover {
    color: var(--primary-hover);
}

a:active {
    color: var(--primary-active);
}

.high {
    color: var(--high);
}

.low {
    color: var(--low);
}