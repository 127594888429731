.Sizer {
    width: 28rem;
    transition: 0.2s width;
}


.Sizer[data-size="wide"] {
    width: 30rem;
}

