.Content {
    --content-height: 15px;
    --content-line: 2px;
    --back-color: var(--white);

    position: relative;
    flex: 1;
    border: var(--gap-l) solid var(--back-color);
    border-radius: var(--radius);
}

.Content[data-collapsible="true"] {
    display: grid;
    grid-template-rows: 0fr;
    transition: grid-template-rows 500ms;
    min-height: 2rem;
}

.Content > .body {
    overflow: hidden;
}

.Content[data-is-open="true"] {
    grid-template-rows: 1fr;
}

.Content[data-theme="blue"] {
    --back-color: var(--background-blue);
}
.Content[data-theme="blue"]>.back {
    background: linear-gradient(90deg, #01A5AF 0%, #E29F96 100%);
}

.Content>.back {
    position: absolute;
    inset: 0;
    height: 100%;
}

.Content>.back>div {
    position: absolute;
    inset: 0;
    background-color: var(--back-color);
}

.Content>.back>div:nth-child(1) {
    height: var(--content-height);
}

.Content[data-no-title="true"]>.back>div:nth-child(1) {
    height: 0;
}

.Content[data-no-title="true"]>.back>div:nth-child(2) {
    top: 0;
    height: 100%;
}

.Content[data-no-title="true"]>.body>div {
    margin-top: 0;
}

.Content>.back>div:nth-child(2) {
    height: calc(100% - calc(var(--content-height) + var(--content-line)));
    top: calc(var(--content-height) + var(--content-line));
}

.Content>.header-wrap {
    position: absolute;
    z-index: 3;
    top: 3px;
    display: flex;
    align-items: center;
    width: 100%;
}

.Content>.header-wrap>.header {
    padding-right: var(--gap);
    background-color: var(--back-color);
    color: var(--primary);
}

.Content>.header-wrap>.icon {
    position: absolute;
    background-color: var(--back-color);
    padding: var(--gap-s);
    right: 0;
    top: -12px;
}

.Content>.header-wrap>.icon:hover {
    cursor: pointer;
    opacity: 0.8;
}

.Content>.header-wrap>.icon:active {
    opacity: 0.9;
}

.Content>.header-wrap>.icon svg {
    width: 25px;
    height: 25px;
    color: var(--primary);
}

.Content>.body {
    position: relative;
    z-index: 2;
}

.Content>.body>div {
    margin-top: calc(var(--content-height) + var(--gap-l));
}