.RoundBox {
    width: 27rem;
    background-color: var(--white);
    border-radius: 16px;
    position: relative;
}

.RoundBox[data-decor="true"] {
    height: 24.95rem;
}

.RoundBox[data-decor="true"]>* {
    position: absolute;
    inset: 0;
}

.RoundBox>.decor img {
    position: absolute;
    left: -3px;
    width: calc(100% + 6px);
}

.RoundBox>.content {
    padding: var(--gap-l);
}

.RoundBox[data-decor="true"]>.content {
    padding: 0 var(--gap-l);
}