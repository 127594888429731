.Overflow {
    position: relative;
}

.Overflow > .content {
    overflow: auto;
    height: 100%;

    scrollbar-width: thin; /* "auto" | "thin" | "none" */
    scrollbar-color: #ddd #f1f1f1; /* thumb and track color */
}

.Overflow::-webkit-scrollbar {
    width: 6px; /* width of the entire scrollbar */
}

.Overflow::-webkit-scrollbar-thumb {
    background: #ddd; /* color of the scroll thumb */
    border-radius: 6px; /* roundness of the scroll thumb */
}

.Overflow > .shade {
    position: absolute;
    left: 0;
    width: 100%;
    height: 40px;
}

.Overflow > .shade:nth-child(1) {
    top: 0;
    background: linear-gradient(to bottom, #ffffff 45%, rgba(255, 255, 255, 0) 97.5%);
}

.Overflow > .shade:nth-child(2) {
    bottom: 0;
    background: linear-gradient(to top, #ffffff 45%, rgba(255, 255, 255, 0) 97.5%);
}