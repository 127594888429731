h1 {
    font-size: 3.175rem;
    line-height: 4.125rem;
    font-weight: var(--font-weight-bold);
}

h2 {
    font-size: 2.5rem;
    line-height: 3.25rem;
}

h3 {
    font-size: 2rem;
    line-height: 2.5rem;
}

h4 {
    font-size: 1.45rem;
    line-height: 1.75rem;
    font-weight: var(--font-weight-medium);
}

p {
    color: var(--natural);
}

[typo-types="body1"] {
    font-size: 1.125rem;
    line-height: 1.75rem;
}

[typo-types="body2"] {
    font-size: 1.2rem;
    line-height: 1.8rem;
}

[typo-types="subtitle1"] {
    font-size: 1.2rem;
    line-height: 1.5rem;
}

[typo-types="subtitle2"] {
    font-size: 0.775rem;
    line-height: 1.25rem;
}

[typo-types="info"] {
    font-size: 0.9rem;
    line-height: 1.25rem;
    color: var(--gray-text);
}

[typo-types="label1"] {
    font-size: 1.25rem;
    line-height: 1.5rem;
}

[typo-types="label2"] {
    font-size: 1.1rem;
    line-height: 1.55rem;
}


[typo-weight="light"] {
    font-weight: var(--font-weight-light);
}

[typo-weight="regular"] {
    font-weight: var(--font-weight-regular);
}

[typo-weight="medium"] {
    font-weight: var(--font-weight-medium);
}

[typo-weight="bold"] {
    font-weight: var(--font-weight-bold);
}

[typo-align="left"] {
    text-align: left;
}

[typo-align="right"] {
    text-align: right;
}

[typo-align="center"] {
    text-align: center;
    margin: 0 auto;
}

[typo-types] b {
    display: inline-block;
}