.Line {
    display: flex;
    gap: var(--gap);
    align-items: center;
    flex-wrap: wrap;
}

.Line.no-wrap {
    flex-wrap: nowrap;
    gap: var(--gap-xs)
}

.Line.no-wrap-top {
    flex-wrap: nowrap;
    align-items:start;
}

.Line.short {
    gap: var(--gap-xs);
}

.Line.medium {
    gap: var(--gap-m);
}

.Line.end {
    justify-content: flex-end;
    width: 100%;
}

.Line.between {
    justify-content: space-between;
    width: 100%;
    flex-wrap: nowrap;
}

.Line.center,
.Line.center-align {
    justify-content: center;
    width: 100%;
    height: 100%;
}

.Line.center-align {
    text-align: center;
}

.Line.middle {
    display: grid;
    height: 100%;
}

.Line.start-align {
    justify-content: flex-start;
    text-align: start;
}

.Line.spread {
    width: 100%;
}

.Line.spread>*:first-child {
    flex-grow: 1;
}

.Line.spread-even {
    flex-wrap: wrap;
}

.Line.spread-even>* {
    flex: 1;
}

.Line.spread-even-base {
    flex-wrap: wrap;
    align-items: flex-start;
}

.Line.spread-even-base>* {
    flex: 1;
}

.Line.spread-end {
    width: 100%;
}

.Line.spread-end>*:last-child {
    flex-grow: 1;
}

.Line.rows {
    flex-direction: column;
    align-items: stretch;
}

.Line.rows.full {
    width: 100%;
}

.Line.rows.full_high {
    gap: var(--gap-m);
}


.Line.center>.Line,
.Line.rows>.Line {
    height: auto;
}

.Line.self-start {
    align-self: flex-start;
}

.Line.rows.start {
    align-items: flex-start;
    gap: var(--gap-xs);
}


.Line.self-end {
    align-self: flex-end;
}