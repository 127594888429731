.Grid {
    display: grid;
}

.Grid[layout="rows"] {
    gap: var(--gap-xl);
}

.Grid[layout="main"] {
    grid-template-rows: var(--top-height-size) 1fr;
}

.Grid[layout="main"]>* {
    display: grid;
    padding: var(--gap-l);
}

.Grid[layout="main"]>:first-child {
    width: calc(100% - (var(--gap-l) * 2));
    padding: 0 var(--gap-l);
    z-index: 800;
    box-shadow: 1px 1px var(--top-shadow-size) var(--color);
    background-color: var(--white-trans);
}

.Grid[layout="10,fr,30"] {
    grid-template-columns: 18rem 1fr 30rem;
}

.Grid[layout="10,fr,30"]>* {
    overflow-y: auto;
    overflow-x: hidden;
}

.Grid[layout="10,fr,30"]>:nth-child(2) {
    justify-self: center;
}

.Grid[layout="auto,200"] {
    grid-template-columns: auto 200px;
    grid-column-start: 1;
}

.Grid.top.auto {
    grid-template-rows: auto minmax(25rem, 1fr);
}

.Grid.top>*:first-child {
    border-bottom: 1px solid var(--gray);
}

.Grid.top>* {
    padding: var(--gap);
}

.Grid[layout="side"] {
    grid-template-columns: auto 1fr;
    gap: var(--gap-l);
}

/* platform */
.Grid[layout="platform"] {
    grid-template-columns: 2fr 2fr;
    gap: var(--gap);
}

.Grid.even {
    grid-template-columns: 1fr 1fr;
    gap: var(--gap)
}

.Grid.triple {
    grid-template-columns: 1fr 1fr 1fr;
    gap: var(--gap)
}

.Grid.four {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: var(--gap-l)
}

.Grid[layout="score"] {
    grid-template-rows: auto 25rem auto;
    gap: var(--gap)
}