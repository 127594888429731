.Input {
    background-color: var(--white);
    display: flex;
    border: 1px solid var(--input);
    border-radius: var(--radius);
    min-height: 3.5rem;
}

.Input svg {
    color: var(--input);
    padding-left: var(--gap-s);
}

.Input:has(:focus) {
    outline: 1px solid var(--input);
}

.Input:has(:focus) svg {
    color: var(--black);
}

.Input input {
    color: var(--input);
    border: none;
    border-radius: inherit;
    padding: var(--gap-s);
    flex: 1;
    color: var(--natural);
    font-size: 1rem;
}

.Input[data-variant="round"] {
    width: 3.5rem;
    height: 3.5rem;
    border: none;
}

.Input[data-variant="round"] input {
    width: 100%;
    text-align: center;
    font-weight: 600;
}

.Section:has(.Input[data-variant="round"]) {
    width: auto;
}

.Input[data-variant="thin"] {
    border: none;
    min-height: auto;
}

.Section:has(.Input[data-variant="thin"]) {
    width: 25rem;
}

::placeholder {
    color: var(--input);
    opacity: 1;
}

:-ms-input-placeholder {
    color: var(--input);
}

::-ms-input-placeholder {
    color: var(--input);
}

textarea:focus, input:focus{
    outline: 0 none;
}
