.Inner {
    max-width: 1524px;
    margin: 0 auto;
    width: 100%;
    display: inherit;
}

.Inner.side {
    margin: 0;
}

.Inner.thin {
    max-width: 410px;
}

.Inner.small {
    max-width: 550px;
}

.Inner.med {
    max-width: 900px;
}