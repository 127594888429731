:root {
    --font-size: 14px;
    --font-family: 16px;
    --font-family: 'Space Grotesk', sans-serif;
    --font-weight-light: 300;
    --font-weight-regular: 400;
    --font-weight-medium: 500;
    --font-weight-bold: 500;

    --font-weight: var(--font-weight-regular);

    --primary: #01A5AF;
    --primary-opac: #01a6af2a;
    --primary-hover: #0097A0;
    --primary-active: #00848C;
    --primary-low: #E0EFF6;
    --primary-shade: #EBF4F9;
    --third: #E791A1;
    --secondary-a: #130402;
    --secondary-b: #0A1433;
    --secondary-c: #0A060E;
    --error: #DA2F43;
    --error-300: #a22332;
    --warning: #120E02;
    --success: #01140C;
    --natural: #0A0A0A;

    --color-natural: #ADADAD;
    --color-high: #DB586F;
    --color-low: #FFBA33;

    --stroke: #D9DBE9;
    --input: #a2a3a9;
    --background: #F7F7FC;
    --background-gray: #E5E5E5;
    --background-blue: #F1F7FA;
    --gray: #EBEBEB;
    --gray-opac: #ebebebb5;
    --gray-text: #C2C2C2;
    --gray-sharp: #959595;
    --white: #FFF;
    --white-hover: #f1f1f1;
    --white-active: #f9f9f9;
    --white-trans: #ffffff80;
    --black: #333;

    --gap: 1rem;
    --gap-p: 1.2rem;
    --gap-xs: 0.4rem;
    --gap-s: 0.9rem;
    --gap-m: 2rem;
    --gap-l: 2.5rem;
    --gap-xl: 4rem;
    --radius: 8px;

    --color: var(--natural);
    --top-height-size: 5.5rem;

    --valid: #05D785;
    --valid-300: #44E1A1;

    --theme-whatsapp: #09D785;
    --theme-email: #01A5AF;

    --high: #E2A3A1;
    --low: #FED785;
}