.Info {
    position: relative;   
    cursor:default;
    align-self: flex-start;
    margin-top: 4px;
}

.Info[data-is-disp="true"] {
    color: var(--primary);
}

.Info > .screen {
    position: fixed;
    background-color: var(--black);
    color: var(--white);
    padding: var(--gap);
    border-radius: 8px;
    transform: translateX(-50%);
    width: 180px;
    word-wrap: break-word;
}

.Info .triangle {
    position: absolute;
    color: var(--black);
    top: -12px;
    left: 50%;
    transform: translateX(-50%);
}