.Popup {
    position: fixed;
    background-color: #33333375;
    inset: 0;
    z-index: 10;
}

.Popup>.screen {
    position: absolute;
    inset: 0;
    max-height: 100svh;
    overflow: auto;
    display: flex;
    transform: translateY(100svh);
    transition: 0.5s transform;
}

.Popup>.screen>.content {
    background-color: #fff;
    border-radius: var(--radius);
    min-width: 24rem;
    min-height: 10rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    gap: var(--gap-xl);
    margin: auto;
    position: relative;
}

.Popup>.screen>.content .close_btn {
    position: absolute;
    right: var(--gap-l);
    top:var(--gap-l);
}

.Popup.shown>.screen {
    transform: translateY(0%);
}

[data-media="popup-msg"] {
    width: 33rem;
}
.Toast {
    position: fixed;
    bottom: var(--gap-l);
    left: var(--gap-l);
    background-color: var(--valid);
    padding: var(--gap-s);
    color: var(--white);
    z-index: 1000000;
    transform: translateX(-100svw);
    transition: 0.5s transform;
    min-width: 20rem;
    border-radius: 8px;
}

.Toast.shown {
    transform: translateX(0);
}

.Toast .Icon[data-shape="round"] {
    background-color: var(--valid-300);
}

.Toast svg path {
    fill: var(--white);
}

.Toast[data-type="error"] {
    background-color: var(--error);
}

.Toast[data-type="error"] .Icon[data-shape="round"] {
    background-color: var(--error-300);
}