.Table {
    display: grid;
}

.Table > div {
    overflow: auto;
}

.Table table {
    background-color: #fff;
    border-radius: 8px;
    border-collapse: collapse;
    min-width: 50rem;
    width: 100%;
}


.Table thead td {
    font-weight: bold;
    color: var(--gray-sharp);
    padding: var(--gap);
}

.Table tr {
    white-space: nowrap;
}

.Table td {
    padding: var(--gap-s);
    border-bottom: 1px solid var(--gray);
}

.Table tbody tr:last-child td {
    border-bottom: none;
}

.Table tbody tr:hover {
    box-shadow: 0px 0px 5px #b2b2b2;
}

.Table [data-is-sort="true"] {
    font-weight: bold;
    color: var(--black);
}

.Table [data-is-sort="true"] .sort {
    position: relative;
}

.Table [data-is-sort="true"] .sort > * {
    position: absolute;
    transform: translateY(-50%);
}