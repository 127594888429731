.Legals {
    display: grid;
    gap:var(--gap-l);
}

.Legals > .top {
    display: flex;
    align-items: self-start;
    gap: var(--gap-l);
    justify-content: flex-start;
}

.Legals  section { 
    display: flex;
    gap: var(--gap);
    align-items: flex-start;
}

.Legals svg {
    width: 35px;
    flex-shrink: 0;
}

.Legals a {
    color: var(--black);
    text-decoration: underline;
    font-weight: var(--font-weight-normal);
    font-size: inherit;
}


.Legals > .bottom {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: var(--gap-l);
    align-items: center;
}

.Legals > .bottom section {
    align-items: center;
    justify-content: center;
}

.Legals > .bottom section:nth-child(1) svg {
    width: 60px;
}