.Sep {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: var(--gap-l);
}

.Sep>.grad {
    background: linear-gradient(90deg, #01A5AF 0%, #E29F96 100%);
    height: 1px;
    flex: 1;
}

.SepLine {
    width: 100%;
    border-bottom: 1px solid var(--gray);
}