.Section {
    position: relative;
    width: 100%;
}

.Section[data-validation="true"] .err-single {
    color: var(--error);
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #fff;
}

.Section[data-validation="true"] .err-list {
    color: var(--input);
}

.Section[data-validation="true"] .err-list > label {
    color: var(--error);
    display: block;
    margin: var(--gap-xs) 0;
    text-align: left;
}

.Section[data-validation="true"] .err-list li,
.Section[data-validation="true"] .err-list li p {
    color: var(--input);
}

.Section[data-validation="true"] .err-list li.is-error,
.Section[data-validation="true"] .err-list  li.is-error p {
    color: var(--error);
}


.Section[data-validation="true"] .err-list ul {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 0;
}

.Section[data-validation="true"] .err-list li {
    text-align: left;
    margin-left: var(--gap);
}

.Section[data-validation="true"] .Input {
    border-color: var(--error);
}

.Section[data-validation="true"] [data-checked] {
    border-color: var(--error);
}