.Background {
    position: relative;
    height: 100%;
}

.Background>* {
    position: absolute;
    inset: 0;
}

.Background>.back {
    z-index: 0;
    overflow: hidden;
    opacity: 1;
}

.Background>.back img {
    width: 100%;
    min-height: 100%;
    object-fit: cover;
    object-position: center;
}

.Background>.content {
    z-index: 1;
}