.Dropdown {
    width: fit-content;
    position: relative;
}

.Dropdown>.header {
    padding: var(--gap-s) var(--gap-s);
    border: 2px solid var(--primary);
    border-radius: 8px;
}

.Dropdown>.header:hover {
    cursor: pointer;
    background-color: var(--light-gray);
}

.Dropdown>.header *:hover {
    cursor: pointer;
    background-color: var(--light-gray);
}

.Dropdown>.header:active {
    background-color: var(--white);
}

.Dropdown>.list {
    display: none;
    border: 1px solid var(--gray);
    position: fixed;
    top: 0;
    left: 0;
    min-width: 100%;
    width: max-content;
    box-sizing: border-box;
    z-index: 1000;
    background-color: var(--white);
}

.Dropdown>.list>* {
    padding: var(--gap-m);
    background-color: var(--white);
    border-bottom: 1px solid var(--gray);
}

.Dropdown>.list>*.selected {
    background-color: var(--gray);
}

.Dropdown>.list>*:hover {
    cursor: pointer;
    background-color: var(--gray-opac);
}

.Dropdown>.list>*:active {
    background-color: var(--white);
}