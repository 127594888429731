.MarkdownShow {
    max-width: 730px;
    padding-left: 5rem;
    padding-right: 5rem;
}

.MarkdownShow a:not([href]) {
    color: inherit;
}

.MarkdownShow span {
    font-size: inherit !important;
    color: inherit !important;
    font-family: inherit !important;
    font-weight: inherit !important;
    display: inline;
}

.MarkdownShow ul {
    margin-bottom: 1rem;
}

.MarkdownShow ul li {
    margin-top: 1rem;
    padding-left: 1rem;
}

.MarkdownShow h1 {
    font-size: 2.5rem;
    color: inherit;
    font-weight: 700;
}

.MarkdownShow a {
    color: blue;
    text-decoration: underline;
}

.MarkdownShow a:not([href]) {
    color: inherit;
}

.MarkdownShow span {
    font-size: inherit !important;
    color: inherit !important;
    font-family: inherit !important;
    font-weight: inherit !important;
    display: inline;
}

.MarkdownShow h1 {
    font-size: 1.5rem;
    color: var(--primary);
    font-weight: 700;
    line-height: 3.5rem !important;
    margin-top: 2rem;
}

.MarkdownShow h2 {
    font-size: inherit;
    line-height: inherit;
}

.MarkdownShow h3 {
    font-size: 1rem;
    font-weight: 700;
    line-height: inherit;
}

.MarkdownShow p {
    font-size: 1rem;
    font-weight: 100;
    margin-bottom: 1rem;
}

.MarkdownShow p.L4Level4-Sprintlaw {
    display: grid;
    grid-template-columns: 5rem auto;
}

.MarkdownShow p.L5Level5-Sprintlaw {
    margin-left: 5rem;
    display: grid;
    grid-template-columns: 5rem auto;
}

.MarkdownShow table {
    border-right: 1px solid #e1e1e1;
    border-collapse: collapse;
    width: auto !important;
}

.MarkdownShow table h2 {
    font-size: inherit;
    color: inherit;
    line-height: inherit;
}

.MarkdownShow thead th {
    background-color: #e1e1e1;
    padding: var(--gap);
}

.MarkdownShow tr:first-child td {
    color: var(--gray);
    vertical-align: middle;
}

.MarkdownShow tr:first-child td p {
    margin-bottom: 0;
}

.MarkdownShow td {
    border-bottom: 1px solid #e1e1e1;
    border-left: 1px solid #e1e1e1;
    vertical-align: baseline;
    padding: var(--gap);
    width: auto !important;
}

.MarkdownShow .table-wrapper {
    width: 100%;
    overflow: auto;
}


.MarkdownShow ol {
    list-style: decimal;
    margin-left: 1rem;
}