/* large screen, larger then 992 */
@media screen and (min-width: 992px) {
    [data-media="patient-info"] {
        grid-template-columns: repeat(4, 1fr);
    }

}

/* medium screens, smaller then 992 */

@media screen and (max-width: 992px) {
    body .Grid[layout="platform"] {
        grid-template-columns: 3fr 1fr;
    }

    body .Grid.even[data-media="score"] {
        grid-template-columns: 1fr;
    }

    [data-media="patient-info"] {
        grid-template-columns: repeat(2, 1fr);
    }
}

/* small screens, smaller then 800px */

@media screen and (max-width: 800px) {
    body .Grid.triple[data-media="answers"] {
        grid-template-columns: 1fr 1fr;
    }
    body .Grid.even[data-media="answers"] {
        grid-template-columns: 1fr;
    }
    body .Grid[layout="platform"] {
        grid-template-columns: 1fr;
    }

    body .Grid.even[data-media="score"] {
        grid-template-columns: 1fr;
    }

    body .Btn[data-media="btn"] {
        padding: 0;
    }

    [data-media="btn"] .text {
        display: none;
    }

    [data-media="patient-info"] {
        grid-template-columns: repeat(2, 1fr);
    }

    body .Sizer[data-size="wide"][data-media="sizer"] {
        width: 100vw;
    }

    body .Sizer[data-media="sizer"] {
        width: 90vw;
    }

    body .Dropdown[data-media="dropdown-icon"] label {
        display: none;
    }
}

/* smaller screens, smaller then 500px */

@media screen and (max-width: 500px) {
    :root {
        --font-size: 14px;
    }

    body [data-media="popup-msg"] {
        width: 100%;
    }
}

[media="risk-score"] {
   
}

@container (inline-size < 600px) {
    body [media="risk-score"] > .Grid {
        grid-template-columns: 1fr;
        grid-template-rows: auto auto;
        justify-items: center;
    }
}