.Icon img {
    width: 100%;
}

.Icon path {
    --fill: var(--natural);
}

.Icon[data-clickable="true"] {
    cursor: pointer;
}

.Icon[data-clickable="true"]:hover {
    opacity: 0.8;
}

.Icon[data-clickable="true"]:active {
    opacity: 0.9;
}

.Icon[data-size="logo-large"] {
    width: 17.809rem;
}

.Icon[data-size="logo-medium"] {
    width: 12.951rem;
}

.Icon[data-size="logo-small"] {
    width: 8.095rem;
}

.Icon[data-shape="round"] {
    display: flex;
    background-color: var(--background-blue);
    border-radius: 50%;
    padding: var(--gap);
}

.Icon[data-shape="round"].big {
    padding: var(--gap-p);
}

.Icon[data-shape="round"][data-theme="whatsapp"] {
    background-color: var(--theme-whatsapp);
} 

.Icon[data-shape="round"][data-theme="whatsapp"] path {
    --fill: var(--white);
}

.Icon[data-shape="round"][data-theme="email"] {
    background-color: var(--theme-email);
} 

.Icon[data-shape="round"][data-theme="email"] path {
    --fill: var(--white);
}


.icon_text {
    position: relative;
    width: 227px;
    aspect-ratio: 1/1;
    
    .text {
        position: absolute;
        inset: 0;
        display: flex;
        gap: .5rem;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-size: 1.2rem;
        font-weight: bold;
        color: currentColor;
    }
}

.icon_text[data-color="high"] {
    color: #DB979B;
}

.icon_text[data-color="low"] {
    color: #FFD685;
}

.icon_text .text p {
    line-height: 0;
    font-size: var(--font-size-l);
    color: currentColor;
}

.Dot {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: var(--color-natural);
    flex: none;
}

.Dot[data-color="high"] {
    background-color: var(--color-high);
}

.Dot[data-color="low"] {
    background-color: var(--color-low);
}

.gif_query {
    position: relative;
}

.gif_query svg {
    position: absolute;
    transform: translateY(-50%);
}